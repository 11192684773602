import { OrganizationAnalyticsPrograms } from '../components/Organization/Details';

export function OrganizationAnalyticsProgramsRoute() {
  return <OrganizationAnalyticsPrograms />;
}

export const Component = OrganizationAnalyticsProgramsRoute;

export function clientLoader() {
  return null;
}
